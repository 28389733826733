<template>
    <div class="input-container">
        <input type="text" class="input-parameters" :maxlength="type === 'hours' ? 2 : 4" v-model="param" :class="{'input-error' : error}" @input="checkData" :onkeydown="validationData">
        <button v-if="showCancel" class="button button-input" @click="resetToDefaultParameter">Cancel</button>
    </div>
</template>

<script>
export default {
  name: 'Input component',
  props: ['type', 'parameters', 'pattern', 'parameterName', 'maxValue', 'resetCancel'],
  data() {
    return {
        param: 0,
        error: false,
        showCancel: false
    }
  },
  watch: {
    parameters: function() {
        this.param = this.parameters
        this.$emit(`${this.parameterName}`, { data: this.param, error: this.error });
    },
    resetCancel: function() {
        if(this.resetCancel) {
            this.showCancel = false;
        }
    }
  },
  methods: {
    checkData(e) {
        if(this.pattern === 'period') {
            if(parseInt(this.param) > parseInt(this.maxValue) || !this.param) {
              this.error = true;
           } else {
              this.error = false;
           } 
        } else {
           if(parseInt(this.param) >= parseInt(this.maxValue) || !this.param) {
              this.error = true;
           } else {
              this.error = false;
           } 
        }
        this.$emit(`${this.parameterName}`, { data: this.param, error: this.error });
        return this.param != this.parameters ? this.showCancel = true : this.showCancel = false;
    },
    resetToDefaultParameter() {
        this.param = this.parameters;
        this.showCancel = false;
        this.error = false;
    },
    validationData(e) {
        let keys = [',', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowRight', 'ArrowLeft'];
        if(this.pattern !== 'temperature') {
            keys.splice(0, 1);
        }
        if(!keys.includes(e.key)) {
            e.preventDefault()
        } else {
            return true;
        }
    },
  },
  created() {
    this.param = this.parameters;
    this.$emit(`${this.parameterName}`, { data: this.param, error: this.error });
  },
}
</script>

<style lang="scss" scoped>
.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .input-parameters {
        width: 18%;
        border: 2px solid #022259;
        border-radius: 7px;
        background: #FFFFFF;
        height: 25px;
        text-align: center;
        padding: 3px 10px 3px 10px;
        color: #022259;
    }

    .input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        input {
            display: block;
            align-self: center;
            border:none;
            width: 1em;
            text-align: right;
            color: #022259;
            line-height: normal;
        }

        span {
            align-self: center;
            display: block;
            line-height: normal;
            margin-top: 2px;
            margin-left: 1px;
        }
    }

    .button {
        background-color: #022259;
        border: 2px solid #022259;
        border-radius: 18px;
        padding: 0 25px 0 25px;
        height: 35px;
        text-align: center;
        color: #FFFFFF;
    }
}

@media screen and (max-width: 1260px) {
    .input-wrapper {
        input {
            width: 5% !important;
        }
    }
}

@media screen and (min-width: 1920px) {
    .container {
        font-size: 18px !important;
        padding: 29px !important;

        .header-title {
            padding-bottom: 10px !important;

            .title-name {
                font-size: 21px !important;

                .subtitle {
                    font-size: 18px !important;
                }
            }
        }
    }

    .left-column, .right-column {
        height: 76px !important;
        
        .column-title {
            font-size: 18px !important;
        }

        .input-container {
            font-size: 18px !important;

            .input-parameters {
                width: 21% !important;
                height: 35px !important;
                font-size: 18px;
            }

            .input-wrapper {
                input {
                    width: 7% !important;
                }

                span {
                    margin-top: 0 !important;
                    margin-bottom: 2px;
                    // margin-left: 1px;
                }
            }

            .right-input {
                width: 21% !important;
            }

            .dropdown {
                top: 43px !important;
            }

            .time-picker {
                height: 35px !important;
                font-size: 18px !important;
                width: 21% !important;
            }

            .vue__time-picker input.vue__time-picker-input {
                font-size: 18px !important;
            }

            .dropdown ul li {
                font-size: 18px !important;
            }
        }
    }

    .first-row-wrapper, .second-row-wrapper {
        font-size: 18px !important;
        margin-top: 20px !important;
    }

    .button {
        height: 45px !important;
        border-radius: 22px !important;
        font-size: 18px;
    }
}

@media screen and (min-width: 2560px) {
    .container {
        font-size: 24px !important;
        padding: 39px !important;

        .header-title {
            .title-name {
                font-size: 36px !important;

                .subtitle {
                    font-size: 24px !important;
                }
            }
        }
    }

    .left-column, .right-column {
        height: 107px !important;
        
        .column-title {
            font-size: 24px !important;
        }

        .input-container {
            font-size: 24px !important;

            .input-parameters {
                height: 50px !important;
                font-size: 24px !important;
            }

            .right-input {
                width: 21% !important;
            }

            .button-input {
                margin-left: 20px !important;
            }

            .dropdown {
                top: 58px !important;
            }

            .time-picker {
                height: 50px !important;
                font-size: 24px !important;
                width: 21% !important;
            }

            .vue__time-picker input.vue__time-picker-input {
                font-size: 24px !important;
            }

            .dropdown ul li {
                font-size: 24px !important;
            }
        }
    }

    .first-row-wrapper, .second-row-wrapper {
        font-size: 24px !important;
        margin-top: 30px !important;
    }

    .button {
        height: 60px !important;
        border-radius: 30px !important;
        font-size: 24px !important;
        padding: 0 35px 0 35px !important;
    }

    .buttons-container {
        margin-top: 30px !important;
    }
}

@media screen and (min-width: 3840px) {
    .container {
        font-size: 36px !important;
        padding: 65px !important;

        .header-title {
            .title-name {
                font-size: 48px !important;

                .subtitle {
                    font-size: 36px !important;
                }
            }
        }
    }

    .left-column, .right-column {
        height: 129px !important;
        
        .column-title {
            font-size: 36px !important;
        }

        .input-container {
            font-size: 36px !important;

            .input-parameters {
                height: 60px !important;
                font-size: 36px !important;
            }

            .line {
                width: 25px !important;
                margin-left: 30px !important;
                margin-right: 30px !important;
                height: 3px !important;
                border-radius: 3px !important;
            }

            .right-input {
                width: 21% !important;
            }

            .button-input {
                margin-left: 25px !important;
            }

            .dropdown {
                top: 68px !important;
            }

            .time-picker {
                height: 60px !important;
                font-size: 36px !important;
                width: 21% !important;
            }

            .vue__time-picker input.vue__time-picker-input {
                font-size: 36px !important;
            }

            .dropdown ul li {
                font-size: 36px !important;
            }
        }
    }

    .first-row-wrapper, .second-row-wrapper {
        font-size: 36px !important;
        margin-top: 40px !important;
    }

    .button {
        height: 70px !important;
        border-radius: 40px !important;
        font-size: 36px !important;
        padding: 0 45px 0 45px !important;
    }

    .buttons-container {
        margin-top: 40px !important;
    }
}
</style>