<template>
    <input type="text" class="input-time" maxlength="2" v-model="time" :onkeydown="validationData" @input="checkData">
</template>

<script>
export default {
  name: 'Input component',
  props: ['type', 'parameters', 'pattern', 'parameterName', 'maxValue', 'resetData', 'resetCancel'],
  data() {
    return {
        time: 0,
        error: false,
        cancel: false
    }
  },
  watch: {
    parameters: function() {
        this.time = this.parameters
        this.normalizeData()
        this.$emit(`${this.parameterName}`, { data: this.time, error: this.error, cancel: this.cancel });
    },
    resetData: function() {
        if(this.resetData) {
            this.time = this.parameters
            this.normalizeData()
        }
    },
    resetCancel: function() {
        if(!this.resetCancel) {
            this.cancel = false;
        }
    }
  },
  methods: {
    checkData(e) {
        if(this.time > this.maxValue || !this.time) {
            this.error = true;
        } else {
            this.error = false;
        }
        if(this.time != this.parameters) {
            this.cancel = true;
        } else {
            this.cancel = false;
        }
        this.$emit(`${this.parameterName}`, { data: this.time, error: this.error, cancel: this.cancel });
    },
    resetToDefaultParameter() {
        this.time = this.parameters;
        this.showCancel = false;
        this.error = false;
    },
    validationData(e) {
        const keys = ['0','1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowRight', 'ArrowLeft'];
        if(!keys.includes(e.key)) {
            e.preventDefault()
        } else {
            return true;
        }
    },
    normalizeData() {
        const date = new Date();
        const currentTimeZoneOffsetInHours = date.getTimezoneOffset() / 60;
        if(this.pattern === 'hours') {
            if(Math.sign(currentTimeZoneOffsetInHours) === -1) {
                this.time = Number(this.time) + Math.abs(currentTimeZoneOffsetInHours);
                if(Number(this.time) >= 24) {
                    this.time = Number(this.time) - 24;
                }
            } else {
                this.time = Number(this.time) - currentTimeZoneOffsetInHours;
                if(Number(this.time) < 0) {
                    this.time = 24 - Math.abs(Number(this.time))
                }
            }
        }
        if(this.time < 10) {
            this.time = `0${this.time}`
        } else {
            this.time = `${this.time}`
        }
    }
  },
  created() {
    this.time = this.parameters;
    this.normalizeData()
    this.$emit(`${this.parameterName}`, { data: this.time, error: this.error, cancel: this.cancel });
  },
}
</script>

<style lang="scss" scoped>
.input-time {
    width: 16px;
    font-size: 14px;
    color: #022259;
    border: none;
}

@media screen and (max-width: 1260px) {
    .input-wrapper {
        input {
            width: 5% !important;
        }
    }
}

@media screen and (min-width: 1920px) {
    .input-time {
        width: 20px;
        font-size: 18px !important;
    }
}

@media screen and (min-width: 2560px) {
    .input-time {
        width: 26px !important;
        font-size: 24px !important;
    }
}

@media screen and (min-width: 3840px) {
    .input-time {
        width: 39px !important;
        font-size: 36px !important;
    }
}
</style>