<template>
  <div class="container">
    <div class="header-container">
        <div class="header-title">
            <p class="title-name">
                <b>Konfiguration</b>
                <span class="subtitle"> - Property</span>
            </p>
        </div>
    </div>
    <div class="first-row-wrapper">
        <div class="left-column">
            <p class="column-title">Start flushing temperature (&deg;C)</p>
            <div class="input-container">
                <v-input 
                  pattern="temperature"
                  maxValue="27"
                  :resetCancel="resetCancel"
                  parameterName="startFlushTemperature"
                  @start-flush-temperature="getStartFlushTemperature"  
                  :parameters="Object.keys(getCurrentProperty).length !== 0 ? getCurrentProperty.parameters.flush_start_temperature : ''"/>
            </div>
        </div>
        <div class="right-column">
            <p class="column-title">Flushing ban time(hh:mm)</p>
            <div class="input-container">
                <div class="inputs-container" :class="{'inputs-container-error': errorStartTimeHours || errorStartTimeMinutes}">
                    <v-input-time 
                      maxValue="23"
                      pattern="hours"
                      parameterName="startTimeHours"
                      :resetData="resetTime"
                      :resetCancel="showCancel"
                      @start-time-hours="getStartTimeHours" 
                      :parameters="Object.keys(getCurrentProperty).length !== 0 ? getCurrentProperty.parameters.flush_night_time_start_disable_hours : ''"/>
                    <span>:</span>
                    <v-input-time 
                      maxValue="59"
                      parameterName="startTimeMinutes"
                      :resetData="resetTime"
                      :resetCancel="showCancel"
                      @start-time-minutes="getStartTimeMinutes"
                      :parameters="Object.keys(getCurrentProperty).length !== 0 ? getCurrentProperty.parameters.flush_night_time_start_disable_minutes : ''"/>
                </div>
                <div class="line"></div>
                <div class="inputs-container" :class="{'inputs-container-error': errorStopTimeHours || errorStopTimeMinutes}">
                    <v-input-time 
                      maxValue="23"
                      pattern="hours"
                      parameterName="stopTimeHours"
                      :resetData="resetTime"
                      :resetCancel="showCancel"
                      @stop-time-hours="getStopTimeHours"
                      :parameters="Object.keys(getCurrentProperty).length !== 0 ? getCurrentProperty.parameters.flush_night_time_stop_disable_hours : ''"/>
                    <span>:</span>
                    <v-input-time 
                      maxValue="59"
                      parameterName="stopTimeMinutes"
                      :resetData="resetTime"
                      :resetCancel="showCancel"
                      @stop-time-minutes="getStopTimeMinutes"
                      :parameters="Object.keys(getCurrentProperty).length !== 0 ? getCurrentProperty.parameters.flush_night_time_stop_disable_minutes : ''"/>
                </div>
                <button v-if="showCancel" @click="resetToDefaultData" class="button button-input">Cancel</button>
            </div>
        </div>
    </div>
    <div class="second-row-wrapper">
        <div class="left-column">
            <p class="column-title">Stop flushing temperature (&deg;C)</p>
            <div class="input-container">
               <v-input 
                pattern="temperature"
                maxValue="25"
                :resetCancel="resetCancel"
                parameterName="stopFlushTemperature"
                @stop-flush-temperature="getStopFlushTemperature"  
                :parameters="Object.keys(getCurrentProperty).length !== 0 ? getCurrentProperty.parameters.flush_temperature : ''"/>
            </div>
        </div>
    </div>
    <div class="second-row-wrapper">
        <div class="left-column">
            <p class="column-title">Flushing period (hours)</p>
            <div class="input-container">
                <v-input 
                  pattern="period"
                  parameterName="flushPeriod"
                  maxValue="72"
                  :resetCancel="resetCancel"
                  @flush-period="getFlushPeriod"   
                  type="hours" 
                  :parameters="Object.keys(getCurrentProperty).length !== 0 ? getCurrentProperty.parameters.flush_rule_timer / 3600 : ''"/>
            </div>
        </div>
    </div>
    <div class="buttons-container">
        <button v-if="checkData" class="button" @click="saveConfiguration">Save configuration</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Input from "../Inputs/InputComponent.vue";
import InputTime from "../Inputs/InputTime.vue";

export default {
  name: 'Configuration property',
  props: ['type'],
  components: {
    'v-input': Input,
    'v-input-time': InputTime
  },
  sockets: {
    update: function (updateType) {
      switch (updateType.type) {
        case 'property':
            if(this.getCurrentProperty._id === updateType.data._id) {
                this.resetCancel = true;
                this.showCancel = false;
            }
          break;
      }
    },
  },
  data() {
    return {
        startFlushTemperature: {},
        stopFlushTemperature: {},
        flushPeriod: {},
        startTimeHours: {},
        startTimeMinutes: {},
        stopTimeHours: {},
        stopTimeMinutes: {},
        errorStartTimeHours: false,
        errorStartTimeMinutes: false,
        errorStopTimeHours: false,
        errorStopTimeMinutes: false,
        cancelDefaultTime: false,
        showCancel: false,
        resetTime: false,
        resetCancel: false,
        disableCancel: true
    }
  },
  computed: {
    ...mapGetters(['getCurrentProperty']),
    checkData() {
        return !this.flushPeriod.error 
            && !this.startFlushTemperature.error 
            && !this.stopFlushTemperature.error 
            && !this.startTimeHours.error 
            && !this.startTimeMinutes.error 
            && !this.stopTimeHours.error 
            && !this.stopTimeMinutes.error
        ? true : false;
    }
  },
  methods: {
    ...mapActions(['setPropertyConfiguration']),
    saveConfiguration() {
        if(!this.flushPeriod.error && !this.startFlushTemperature.error && !this.stopFlushTemperature.error && !this.startTimeHours.error && !this.startTimeMinutes.error && !this.stopTimeHours.error && !this.stopTimeMinutes.error) {
           this.setPropertyConfiguration({
                _id: this.getCurrentProperty._id,
                parameters: {
                    flush_rule_timer: this.flushPeriod.data * 3600,
                    flush_night_time_start_disable_hours: this.startTimeHours.data,
                    flush_night_time_start_disable_minutes: this.startTimeMinutes.data,
                    flush_night_time_stop_disable_hours: this.stopTimeHours.data,
                    flush_night_time_stop_disable_minutes: this.stopTimeMinutes.data,
                    flush_start_temperature: this.startFlushTemperature.data,
                    flush_temperature: this.stopFlushTemperature.data
                }
            })
            this.resetCancel = true;
            this.showCancel = false;
        }
    },
    getStartFlushTemperature(data) {
        this.startFlushTemperature = data;
        this.resetCancel = false;
    },
    getStopFlushTemperature(data) {
        this.stopFlushTemperature = data;
        this.resetCancel = false;
    },
    getFlushPeriod(data) {
        this.flushPeriod = data;
        this.resetCancel = false;
    },
    getStartTimeHours(data) {
        this.startTimeHours = data;
        if(data.data) {
            if(this.startTimeHours.data.charAt(0) == 0) {
                this.startTimeHours.data = this.startTimeHours.data.slice(1);
            }
            this.normalizeData('start');
            this.checkCancel(data.cancel);
        }
        return data.error ? this.errorStartTimeHours = true : this.errorStartTimeHours = false;
    },
    getStartTimeMinutes(data) {
        this.startTimeMinutes = data;
        if(data.data) {
            if(this.startTimeMinutes.data.charAt(0) == 0) {
                this.startTimeMinutes.data = this.startTimeMinutes.data.slice(1);
            }
            this.checkCancel(data.cancel);
        }
        return data.error ? this.errorStartTimeMinutes = true : this.errorStartTimeMinutes = false;
    },
    getStopTimeHours(data) {
        this.stopTimeHours = data;
        if(data.data) {
            if(this.stopTimeHours.data.charAt(0) == 0) {
                this.stopTimeHours.data = this.stopTimeHours.data.slice(1);
            }
            this.normalizeData('stop');
            this.checkCancel(data.cancel);
        }
        return data.error ? this.errorStopTimeHours = true : this.errorStopTimeHours = false;
    },
    getStopTimeMinutes(data) {
        this.stopTimeMinutes = data;
        if(data.data) {
            if(this.stopTimeMinutes.data.charAt(0) == 0) {
                this.stopTimeMinutes.data = this.stopTimeMinutes.data.slice(1);
            }
            this.checkCancel(data.cancel);
        }
        return data.error ? this.errorStopTimeMinutes = true : this.errorStopTimeMinutes = false;
    },
    checkCancel(data) {
        return data ? this.showCancel = true : this.showCancel = false
    },
    resetToDefaultData() {
        this.showCancel = false;
        this.errorStartTimeHours = false;
        this.errorStartTimeMinutes = false;
        this.errorStopTimeHours = false;
        this.errorStopTimeMinutes = false;
        this.resetTime = true;
    },
    normalizeData(type) {
        const date = new Date();
        const currentTimeZoneOffsetInHours = date.getTimezoneOffset() / 60;

        if(Math.sign(currentTimeZoneOffsetInHours) === -1) {
            if(type === 'start') {
                this.startTimeHours.data = Number(this.startTimeHours.data) - Math.abs(currentTimeZoneOffsetInHours);
                if(Number(this.startTimeHours.data) < 0) {
                    this.startTimeHours.data = 24 - Math.abs(Number(this.startTimeHours.data))
                }
            } else {
                this.stopTimeHours.data = Number(this.stopTimeHours.data) - Math.abs(currentTimeZoneOffsetInHours);
                if(Number(this.stopTimeHours.data) < 0) {
                    this.stopTimeHours.data = 24 - Math.abs(Number(this.stopTimeHours.data))
                }
            }
        } else {
            if(type === 'start') {
                this.startTimeHours.data = Number(this.startTimeHours.data) + currentTimeZoneOffsetInHours;
                if(Number(this.startTimeHours.data) > 24) {
                    this.startTimeHours.data = Number(this.startTimeHours.data) - 24;
                }
            } else {
                this.stopTimeHours.data = Number(this.stopTimeHours.data) + currentTimeZoneOffsetInHours;
                if(Number(this.stopTimeHours.data) > 24) {
                    this.stopTimeHours.data = Number(this.stopTimeHours.data) - 24;
                }
            }
        }
    }
  }
}
</script>

<style lang="scss">

.disable {
    display: none;
}
.container {
    height: auto;
    background: #EFF9FF;
    border-radius: 10px;
    padding: 20px;

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .header-title {
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(2, 34, 89, 0.3);

            .title-name {
                font-size: 18px;
                color: #022259;

                .subtitle {
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }

    .left-column, .right-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 58px;
        width: 50%;

        p {
            color: #022259;
            font-weight: 500;
        }

        .input-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;

            .inputs-container {
                width: 18%;
                border: 2px solid #022259;
                border-radius: 7px;
                background: #FFFFFF;
                height: 25px;
                padding: 3px 10px 3px 10px;
                color: #022259;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }

            .inputs-container-error {
                border: 2px solid red;
            }

            .input-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;

                input {
                    display: block;
                    align-self: center;
                    border:none;
                    width: 1em;
                    text-align: right;
                    color: #022259;
                    line-height: normal;
                }

                span {
                    align-self: center;
                    display: block;
                    line-height: normal;
                    margin-top: 2px;
                    margin-left: 1px;
                }
            }

            .input {
                width: 55%;
                border: 2px solid #022259;
                border-radius: 7px;
                background: #FFFFFF;
                height: 25px;
                padding: 3px 10px 3px 10px;
                color: #022259;
            }

            .input-error {
                border: 2px solid red;
            }

            .button-input {
                margin-left: 10px;
            }
        }

    }

    .first-row-wrapper {
        margin-top: 21px;
        display: flex;
        flex-direction: row;
        font-size: 14px;

        .left-column, .right-column {
            .input-container {
                .line {
                    width: 18px;
                    margin-left: 20px;
                    margin-right: 20px;
                    height: 2px;
                    background-color: #022259;
                    border-radius: 1px;
                }

                .time-picker {
                    text-align: center;
                    border: 2px solid #022259;
                    border-radius: 7px;
                    height: 25px;
                    width: 15%;
                    padding: 3px 10px 3px 10px;
                    background: #FFFFFF;
                    color: #022259;
                }

                .vue__time-picker input.vue__time-picker-input {
                    border: none !important;
                    border-radius: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    text-align: center;
                    color: #022259;
                    font-size: 14px;
                }

                .vue__time-picker input.vue__time-picker-input:active {
                    .dropdown {
                        display: block !important;
                    }
                }

                .dropdown {
                    width: 100%;
                    top: 33px;
                }

                .select-list {
                    width: 100%;
                }

                .dropdown ul li {
                    font-size: 14px;
                }

                .hours .active {
                    background-color: #022259;
                }

                .minutes .active {
                    background-color: #022259;
                }

                .clear-btn {
                    display: none;
                }

                .right-input {
                    text-align: center;
                    width: 15%;
                }
            }
        }
    }

    .second-row-wrapper {
        font-size: 14px;
        margin-top: 21px;
    }

    .button {
        background-color: #022259;
        border: 2px solid #022259;
        border-radius: 18px;
        padding: 0 25px 0 25px;
        height: 35px;
        text-align: center;
        color: #FFFFFF;
    }

    .buttons-container {
        margin-top: 21px;
        display: flex;
        flex-direction: row;
        font-size: 14px;

        .button:first-child {
            margin-right: 45px;
        }
    }

}

@media screen and (max-width: 1260px) {
    .input-wrapper {
        input {
            width: 5% !important;
        }
    }
}

@media screen and (min-width: 1920px) {
    .container {
        font-size: 18px !important;
        padding: 29px !important;

        .header-title {
            padding-bottom: 10px !important;

            .title-name {
                font-size: 21px !important;

                .subtitle {
                    font-size: 18px !important;
                }
            }
        }
    }

    .left-column, .right-column {
        height: 76px !important;
        
        .column-title {
            font-size: 18px !important;
        }

        .input-container {
            font-size: 18px !important;

            .inputs-container {
                width: 21% !important;
                height: 35px !important;
            }

            span {
                margin-bottom: 3px;
                font-size: 18px !important;
            }

            .input {
                width: 60% !important;
                height: 35px !important;
                font-size: 18px;
            }

            .input-wrapper {
                input {
                    width: 7% !important;
                }

                span {
                    margin-top: 0 !important;
                    margin-bottom: 2px;
                    // margin-left: 1px;
                }
            }

            .right-input {
                width: 21% !important;
            }

            .dropdown {
                top: 43px !important;
            }

            .time-picker {
                height: 35px !important;
                font-size: 18px !important;
                width: 21% !important;
            }

            .vue__time-picker input.vue__time-picker-input {
                font-size: 18px !important;
            }

            .dropdown ul li {
                font-size: 18px !important;
            }
        }
    }

    .first-row-wrapper, .second-row-wrapper {
        font-size: 18px !important;
        margin-top: 20px !important;
    }

    .button {
        height: 45px !important;
        border-radius: 22px !important;
        font-size: 18px;
    }
}

@media screen and (min-width: 2560px) {
    .container {
        font-size: 24px !important;
        padding: 39px !important;

        .header-title {
            .title-name {
                font-size: 36px !important;

                .subtitle {
                    font-size: 24px !important;
                }
            }
        }
    }

    .left-column, .right-column {
        height: 107px !important;
        
        .column-title {
            font-size: 24px !important;
        }

        .input-container {
            font-size: 24px !important;

            .inputs-container {
                width: 21% !important;
                height: 50px !important;
            }

            span {
                margin-bottom: 4px !important;
                font-size: 24px !important;
            }

            .input {
                width: 60% !important;
                height: 50px !important;
                font-size: 24px !important;
            }

            .right-input {
                width: 21% !important;
            }

            .button-input {
                margin-left: 20px !important;
            }

            .dropdown {
                top: 58px !important;
            }

            .time-picker {
                height: 50px !important;
                font-size: 24px !important;
                width: 21% !important;
            }

            .vue__time-picker input.vue__time-picker-input {
                font-size: 24px !important;
            }

            .dropdown ul li {
                font-size: 24px !important;
            }
        }
    }

    .first-row-wrapper, .second-row-wrapper {
        font-size: 24px !important;
        margin-top: 30px !important;
    }

    .button {
        height: 60px !important;
        border-radius: 30px !important;
        font-size: 24px !important;
        padding: 0 35px 0 35px !important;
    }

    .buttons-container {
        margin-top: 30px !important;
    }
}

@media screen and (min-width: 3840px) {
    .container {
        font-size: 36px !important;
        padding: 65px !important;

        .header-title {
            .title-name {
                font-size: 48px !important;

                .subtitle {
                    font-size: 36px !important;
                }
            }
        }
    }

    .left-column, .right-column {
        height: 129px !important;
        
        .column-title {
            font-size: 36px !important;
        }

        .input-container {
            font-size: 36px !important;

            .input {
                width: 60% !important;
                height: 60px !important;
                font-size: 36px !important;
            }

            .inputs-container {
                height: 60px !important;
            }

            span {
                margin-bottom: 4px !important;
                font-size: 36px !important;
            }

            .line {
                width: 25px !important;
                margin-left: 30px !important;
                margin-right: 30px !important;
                height: 3px !important;
                border-radius: 3px !important;
            }

            .right-input {
                width: 21% !important;
            }

            .button-input {
                margin-left: 25px !important;
            }

            .dropdown {
                top: 68px !important;
            }

            .time-picker {
                height: 60px !important;
                font-size: 36px !important;
                width: 21% !important;
            }

            .vue__time-picker input.vue__time-picker-input {
                font-size: 36px !important;
            }

            .dropdown ul li {
                font-size: 36px !important;
            }
        }
    }

    .first-row-wrapper, .second-row-wrapper {
        font-size: 36px !important;
        margin-top: 40px !important;
    }

    .button {
        height: 70px !important;
        border-radius: 40px !important;
        font-size: 36px !important;
        padding: 0 45px 0 45px !important;
    }

    .buttons-container {
        margin-top: 40px !important;
    }
}
</style>
