<template>
  <div class="config-wrapper">
    <configuration-device v-if="Object.keys(getCurrentTestingDevice).length !== 0" class="config-block"/>
    <configuration-property class="config-block"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfigurationDevice from "./ConfigurationDevice.vue";
import ConfigurationProperty from "./ConfigurationProperty.vue";

export default {
  name: 'Configuration block',
  components: {
    ConfigurationDevice,
    ConfigurationProperty
  },
  computed: {
    ...mapGetters(['getCurrentTestingDevice'])
  }
}
</script>

<style lang="scss" scoped>
.config-wrapper {
  overflow-x: auto;
  height: calc(100% - 5px);

  .config-block { 
    margin-bottom: 13px;
  }
  
  .config-block:last-child {
    margin-bottom: 0;
  }
}

::-webkit-scrollbar {
  background-color: #E5E5E5;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  margin-right: 5px;
  background-color: #022259;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #003797;
}

@media screen and (min-width: 2560px) {
  .config-block {
    margin-bottom: 29px !important;
  }

  .config-block:last-child {
    margin-bottom: 0 !important;
  }
}
</style>
